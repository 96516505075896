// Application
export const LOG_OUT = "LOG_OUT";
export const SET_APP_LOADING = "SET_APP_LOADING";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_AGENT_PAGE_LOADING = "SET_AGENT_PAGE_LOADING";
export const SET_AGENT_LOGIN_DATE = "SET_AGENT_LOGIN_DATE";

// Auth
export const AUTH_STATE_FETCHED = "AUTH_STATE_FETCHED";

// Agent
export const AGENT_INFO_FETCHED = "AGENT_INFO_FETCHED";
export const AGENT_USERS_FETCHED = "AGENT_USERS_FETCHED";
export const AGENT_BALANCE_FETCHED = "AGENT_BALANCE_FETCHED";
export const UPDATE_AGENT_STATUS = "UPDATE_AGENT_STATUS";
export const SET_PUBLIC_IP = "SET_PUBLIC_IP";
export const SET_IS_CLASSIC_VIEW = "SET_IS_CLASSIC_VIEW";
export const SET_IS_DISTRIBUTOR = "SET_IS_DISTRIBUTOR";
export const SET_SELECTED_AGENT = "SET_SELECTED_AGENT";

// Products etc
export const PRODUCTS_FETCHED = "PRODUCTS_FETCHED";
export const CALL_PACKAGES_FETCHED = "CALL_PACKAGES_FETCHED";
export const COUNTRIES_FETCHED = "COUNTRIES_FETCHED";

// Invoices/Reports
export const INVOICES_FETCHED = "INVOICES_FETCHED";
export const RECHARGE_REPORT_FETCHED = "RECHARGE_REPORT_FETCHED";
export const SET_SELECTED_DATE_RANGE_RECHARGE_REPORT =
  "SET_SELECTED_DATE_RANGE_RECHARGE_REPORT";
export const SET_SELECTED_START_DATE_ONLY_RECHARGE_REPORT =
  "SET_SELECTED_START_DATE_ONLY_RECHARGE_REPORT";
export const SET_SELECTED_END_DATE_ONLY_RECHARGE_REPORT =
  "SET_SELECTED_END_DATE_ONLY_RECHARGE_REPORT";
export const RESET_DATE_RANGE_RECHARGE_REPORT =
  "RESET_DATE_RANGE_RECHARGE_REPORT";
export const SET_RECHARGE_REPORT_LOADING = "SET_RECHARGE_REPORT_LOADING";
export const SET_FILTERED_RECHARGE_REPORT = "SET_FILTERED_RECHARGE_REPORT";
export const RESET_FILTERED_RECHARGE_REPORT = "RESET_FILTERED_RECHARGE_REPORT";

export const DEPOSIT_REPORT_FETCHED = "DEPOSIT_REPORT_FETCHED";
export const SET_SELECTED_DATE_RANGE_DEPOSIT_REPORT =
  "SET_SELECTED_DATE_RANGE_DEPOSIT_REPORT";
export const SET_SELECTED_START_DATE_ONLY_DEPOSIT_REPORT =
  "SET_SELECTED_START_DATE_ONLY_DEPOSIT_REPORT";
export const SET_SELECTED_END_DATE_ONLY_DEPOSIT_REPORT =
  "SET_SELECTED_END_DATE_ONLY_DEPOSIT_REPORT";
export const RESET_DATE_RANGE_DEPOSIT_REPORT =
  "RESET_DATE_RANGE_DEPOSIT_REPORT";
export const SET_DEPOSIT_REPORT_LOADING = "SET_DEPOSIT_REPORT_LOADING";

// General Report
export const REPORT_FETCHED = "REPORT_FETCHED";
export const SET_SELECTED_DATE_RANGE_REPORT = "SET_SELECTED_DATE_RANGE_REPORT";
export const SET_SELECTED_START_DATE_ONLY_REPORT =
  "SET_SELECTED_START_DATE_ONLY_REPORT";
export const SET_SELECTED_END_DATE_ONLY_REPORT =
  "SET_SELECTED_END_DATE_ONLY_REPORT";
export const RESET_DATE_RANGE_REPORT = "RESET_DATE_RANGE_REPORT";
export const SET_REPORT_LOADING = "SET_REPORT_LOADING";

export const SET_TODAYS_TOTAL_SALES_AMOUNT = "SET_TODAYS_TOTAL_SALES_AMOUNT";
export const SET_TODAYS_TOTAL_SINPIN_SALES_AMOUNT =
  "SET_TODAYS_TOTAL_SINPIN_SALES_AMOUNT";
export const SET_TODAYS_TOTAL_TOPUP_SALES_AMOUNT =
  "SET_TODAYS_TOTAL_TOPUP_SALES_AMOUNT";
export const UPDATE_TODAYS_SALES_SUMMARY_AMOUNTS =
  "UPDATE_TODAYS_SALES_SUMMARY_AMOUNTS";
export const SET_DATE_SALES_SUMMARY_RUN = "SET_DATE_SALES_SUMMARY_RUN";

// Credit Cards
export const CREDIT_CARDS_ON_FILE_FETCHED = "CREDIT_CARDS_ON_FILE_FETCHED";
export const AUTO_RECHARGE_SETTINGS_FETCHED = "AUTO_RECHARGE_SETTINGS_FETCHED";
export const SET_SELECTED_CREDITCARD_PAYMENT_PROFILE_ID =
  "SET_SELECTED_CREDITCARD_PAYMENT_PROFILE_ID";
export const IS_QB_CACHE_RUNNING = "IS_QB_CACHE_RUNNING";
export const QB_CACHE_FETCHED = "QB_CACHE_FETCHED";

// Voided Check
export const SET_VOIDED_CHECK = "SET_VOIDED_CHECK";

// Sanityio.com
export const SANITY_CALL_PACKAGES_FETCHED = "SANITY_CALL_PACKAGES_FETCHED";
export const SANITY_CARRIERS_FETCHED = "SANITY_CARRIERS_FETCHED";
export const SANITY_COUNTRIES_FETCHED = "SANITY_COUNTRIES_FETCHED";
export const ANNOUNCEMENTS_FETCHED = "ANNOUNCEMENTS_FETCHED";
export const DAILY_PROMOS_FETCHED = "DAILY_PROMOS_FETCHED";

// Transactions
export const CUSTOMER_BALANCE_FETCHED = "CUSTOMER_BALANCE_FETCHED";
export const CUSTOMER_TOPUP_HISTORY_FETCHED = "CUSTOMER_TOPUP_HISTORY_FETCHED";
export const SET_PRODUCTID = "SET_PRODUCTID";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const SET_SELECTED_CARRIER = "SET_SELECTED_CARRIER";
export const SET_SELECTED_AMOUNT = "SET_SELECTED_AMOUNT";
export const SET_INTERNATIONAL_NUMBER = "SET_INTERNATIONAL_NUMBER";
export const SET_ADDITIONAL_INFO = "SET_ADDITIONAL_INFO";
export const CLEAR_TRANSACTION_STATE = "CLEAR_TRANSACTION_STATE";
export const CLEAR_TRANSACTION_STATE_ALL_BUT_USER =
  "CLEAR_TRANSACTION_STATE_ALL_BUT_USER";
export const HISTORY_LOADING = "HISTORY_LOADING";

// Transaction Dialog
export const TOGGLE_TRANSACTION_DIALOG = "TOGGLE_TRANSACTION_DIALOG";
export const SET_SCREENTOSHOW_TRANSACTION_DIALOG =
  "SET_SCREENTOSHOW_TRANSACTION_DIALOG";
export const GOTO_PREVIOUS_SCREEN_TRANSACTION_DIALOG =
  "GOTO_PREVIOUS_SCREEN_TRANSACTION_DIALOG";
export const RESET_TRANSACTION_DIALOG = "RESET_TRANSACTION_DIALOG";
export const SET_FILTERED_TOPUP_CARRIERS = "SET_FILTERED_TOPUP_CARRIERS";
export const CLEAR_FILTERED_TOPUP_CARRIERS = "CLEAR_FILTERED_TOPUP_CARRIERS";
export const SET_FILTERED_TOPUP_AMOUNTS = "SET_FILTERED_TOPUP_AMOUNTS";
export const CLEAR_FILTERED_TOPUP_AMOUNTS = "CLEAR_FILTERED_TOPUP_AMOUNTS";

// Transaction Alert
export const SET_SHOW_TRANSACTION_SUCCESS_MESSAGE =
  "SET_SHOW_TRANSACTION_SUCCESS_MESSAGE";
export const SET_SHOW_TRANSACTION_ERROR_MESSAGE =
  "SET_SHOW_TRANSACTION_ERROR_MESSAGE";
export const SET_TRANSACTION_SUCCESS_MESSAGE =
  "SET_TRANSACTION_SUCCESS_MESSAGE";
export const SET_TRANSACTION_ERROR_MESSAGE = "SET_TRANSACTION_ERROR_MESSAGE";
export const SET_ALERT_ORDER_INFO = "SET_ALERT_ORDER_INFO";
export const SET_TRANSACTION_CONFIRMATION_NUMBER =
  "SET_TRANSACTION_CONFIRMATION_NUMBER";
export const CLEAR_TRANSACTION_ALERT_STATE = "CLEAR_TRANSACTION_ALERT_STATE";
