import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import find from "lodash/find";
import filter from "lodash/filter";
import toNumber from "lodash/toNumber";
import map from "lodash/map";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import NumberFormat from "react-number-format";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import TopUpBenefits from "../../Elements/TopUpBenefits";
import TableHeader from "../Headers/TableHeader";
import { getLastPaymentAmount } from "../../../Utils/helperFunctions";
import { setScreenToShow } from "../../../Redux/Actions/transactionDialogActions";
import { setSelectedAmount } from "../../../Redux/Actions/transactionActions";
import colors from "../../../Utils/colors";
import {
  addCommas,
  roundMoneyValueOnly,
} from "../Reports/RechargeReport/reportHelperFunctions";
import { sendMetroBalanceSms } from "../../../Utils/api";
import toast from "../../../Utils/toast";

const styles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "65%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 20,
    color: theme.palette.primary.dark,
    lineHeight: "normal !important",
  },
  iconButton: {
    padding: 10,
  },
  colFlexAlignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  vertDivider: {
    height: 28,
    margin: 4,
  },
  errorText: {
    fontSize: 16,
    color: "red",
  },
  amountButtonsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  lastPaymentText: {
    // color: theme.palette.primary.main,
    color: colors.emphasis,
    fontSize: 14,
    lineHeight: 1,
    textDecoration: "none",
    // fontStyle: "italic",
    fontWeight: "bold",
    marginBottom: theme.spacing(0.6),
    // marginTop: theme.spacing(-0.2),
    // fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    fontSize: 18,
    background: colors.bgGradient9,
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: 10,
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
}))(Button);

class SelectAmount extends Component {
  constructor(props) {
    super(props);
    this.amountInput = React.createRef();
  }

  state = {
    amount: "",
    error: false,
  };

  amountUpdated = (event) => {
    this.setState({ amount: event.target.value, error: false });
  };

  AmountFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        isNumericString
      />
    );
  };

  submitAmount = (e) => {
    e.preventDefault();
    const { products, transaction } = this.props;
    const { amount } = this.state;
    const foundProduct =
      transaction.productID &&
      find(products, {
        ProductID: transaction.productID,
      });
    const openRangeLow = foundProduct && foundProduct.OpenRangeLow;
    const openRangeHigh = foundProduct && foundProduct.OpenRangeHigh;
    if (
      amount <= 0 ||
      amount < openRangeLow ||
      amount > openRangeHigh ||
      !amount
    ) {
      return this.setState({ error: true });
    }
    this.setSelectedAmountFromOpenRange();
  };

  setSelectedAmountFromOpenRange = () => {
    const { transaction, products } = this.props;
    const { amount } = this.state;
    const fee = find(products, {
      ProductID: transaction.productID,
    }).Fee;

    const amountToSet = (toNumber(amount) + toNumber(fee)).toFixed(2);

    this.props.setSelectedAmount(amountToSet);
    this.props.setScreenToShow(5);
  };

  setSelectedAmountClicked = (amount) => {
    const { transaction, products } = this.props;
    const fee = find(products, {
      ProductID: transaction.productID,
    }).Fee;

    const amountToSet = (parseFloat(amount) + fee).toString();

    this.props.setSelectedAmount(amountToSet);
    this.props.setScreenToShow(5);
  };

  onSelectSendMetroSms = async () => {
    const { t, agent, transaction } = this.props;

    try {
      const isMetro = includes(toLower(transaction.carrier), "metro pcs");
      if (!isMetro) return;

      const metroPhone = transaction.mobilephone || "";
      const agentId = agent.ID;
      sendMetroBalanceSms(metroPhone, agentId);

      return toast(t("forms.metroSmsSuccessMsg"), "success");
    } catch (error) {
      return false;
    }
  };

  clearAmount = () => {
    this.setState({ amount: "", error: false });
    this.focusInput();
  };

  focusInput = () => {
    this.amountInput.current.focus();
  };

  renderOpenRange = () => {
    const { t, classes, transaction, products } = this.props;
    const foundProduct =
      transaction.productID &&
      find(products, {
        ProductID: transaction.productID,
      });
    const openRangeLow = foundProduct && foundProduct.OpenRangeLow;
    const openRangeHigh = foundProduct && foundProduct.OpenRangeHigh;
    return (
      <form noValidate autoComplete="off" onSubmit={this.submitAmount}>
        <div className={classes.colFlexAlignCenter}>
          <Paper className={classes.root} elevation={3}>
            <IconButton
              className={classes.iconButton}
              aria-label="dollar"
              onClick={this.focusInput}
            >
              <AttachMoneyOutlinedIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder={`$${openRangeLow} - $${openRangeHigh}`}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onChange={this.amountUpdated}
              inputComponent={this.AmountFormatCustom}
              value={this.state.amount}
              inputRef={this.amountInput}
              autoFocus
            />

            <Divider className={classes.vertDivider} orientation="vertical" />
            <IconButton
              color="primary"
              className={classes.iconButton}
              aria-label="directions"
              disabled={!this.state.amount}
              onClick={this.clearAmount}
            >
              <HighlightOffOutlinedIcon />
            </IconButton>
          </Paper>
          {this.state.error ? (
            <Typography
              component="h2"
              variant="h6"
              className={classes.errorText}
            >
              {`${t(
                "errors.enterAmountBetween"
              )} $${openRangeLow} - $${openRangeHigh}`}
            </Typography>
          ) : null}
          <Button
            variant="contained"
            // color="primary"
            size="large"
            style={{
              marginTop: 10,
              background: colors.bgGradient9,
              color: "#fff",
            }}
            disabled={!this.state.amount}
            onClick={this.submitAmount}
          >
            {t("buttons.next")}
          </Button>
        </div>
      </form>
    );
  };

  renderSelectAmount = () => {
    const { classes, transaction } = this.props;
    return (
      <div className={classes.amountButtonsContainer}>
        {map(transaction.filteredAmounts, (amount) => {
          return (
            <div
              key={amount}
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "3%",
                marginRight: "3%",
                marginBottom: 10,
              }}
            >
              <ColorButton
                variant="contained"
                color="primary"
                size="medium"
                style={{ marginTop: 10 }}
                onClick={() => this.setSelectedAmountClicked(amount)}
              >
                {`$${amount}`}
              </ColorButton>
              <TopUpBenefits selectedAmount={amount} showToolTip />
            </div>
          );
        })}
      </div>
    );
  };

  renderTextMetroBalance = () => {
    const { t } = this.props;

    return (
      <div style={{ marginBottom: 10 }}>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          onClick={this.onSelectSendMetroSms}
        >
          {t("forms.textAmountDue")}
        </Button>
      </div>
    );
  };

  render() {
    const { t, classes, transaction, products } = this.props;
    const foundProduct =
      transaction.productID &&
      find(products, {
        ProductID: transaction.productID,
      });
    const pricingType = foundProduct && foundProduct.PricingType;
    const lastPaymentAmount = getLastPaymentAmount(transaction);
    const isMetro = includes(toLower(transaction.carrier), "metro pcs");

    return (
      <div style={{ marginBottom: 15 }}>
        <div className={classes.colFlexAlignCenter}>
          {pricingType === "OpenRange" && isMetro
            ? this.renderTextMetroBalance()
            : null}
          <TableHeader
            title={
              pricingType === "OpenRange"
                ? `${t("headers.enterAmount")}`
                : `${t("headers.selectAmount")}`
            }
          />
          {lastPaymentAmount && lastPaymentAmount > 0 ? (
            <span className={classes.lastPaymentText}>{`${t(
              "forms.lastPayment"
            )}: $${addCommas(
              roundMoneyValueOnly(lastPaymentAmount, 2)
            )}`}</span>
          ) : null}
        </div>
        {pricingType === "OpenRange" ? this.renderOpenRange() : null}
        {pricingType !== "OpenRange" ? this.renderSelectAmount() : null}
      </div>
    );
  }
}

SelectAmount.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  setScreenToShow: PropTypes.func.isRequired,
  setSelectedAmount: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  agent: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    transaction: state.transaction,
    products: filter(state.products, { Active: true }),
    agent: state.agent,
  };
}

export default connect(mapStateToProps, {
  setScreenToShow,
  setSelectedAmount,
})(withTranslation()(withStyles(styles)(SelectAmount)));
