import axios from "axios";
import { BASE_API_URL } from "../../Utils/constants";
import {
  CUSTOMER_BALANCE_FETCHED,
  CUSTOMER_TOPUP_HISTORY_FETCHED,
  SET_PRODUCTID,
  SET_SELECTED_COUNTRY,
  SET_SELECTED_CARRIER,
  SET_SELECTED_AMOUNT,
  SET_INTERNATIONAL_NUMBER,
  SET_ADDITIONAL_INFO,
  SET_FILTERED_TOPUP_CARRIERS,
  CLEAR_FILTERED_TOPUP_CARRIERS,
  SET_FILTERED_TOPUP_AMOUNTS,
  HISTORY_LOADING,
  CLEAR_FILTERED_TOPUP_AMOUNTS,
  CLEAR_TRANSACTION_STATE_ALL_BUT_USER,
  CLEAR_TRANSACTION_STATE,
} from "../types";
import { checkAuthorized } from "../../Utils/auth";

export const fetchCustomer = (mobilephone) => async (dispatch) => {
  try {
    const results = await axios.get(
      `${BASE_API_URL}/agent/sinpin/balance-with-count/${mobilephone}`
    );
    let balance = results.data.data.Balance;
    if (balance < 0) {
      balance = 0;
    }
    return dispatch(
      customerBalanceFetched(
        balance,
        mobilephone,
        results.data.data.TransactionCount
      )
    );
  } catch (error) {
    checkAuthorized(error);
    throw new Error("Failed to get customer balance");
  }
};

export const fetchCustomerHistory = (mobilephone) => async (dispatch) => {
  try {
    if (!mobilephone || mobilephone.length !== 10) {
      return true;
    }
    dispatch(setHistoryLoading(true));
    const results = await axios.get(
      `${BASE_API_URL}/agent/subscriber/recent-topups/${mobilephone}`
    );

    dispatch(customerTopUpHistoryFetched(results.data.data, mobilephone));
    dispatch(setHistoryLoading(false));
    return true;
  } catch (error) {
    return true;
  }
};

export const setProductID = (productID) => ({
  type: SET_PRODUCTID,
  productID,
});

export const setSelectedCountry = (countryID) => ({
  type: SET_SELECTED_COUNTRY,
  countryID,
});

export const setSelectedCarrier = (carrier) => ({
  type: SET_SELECTED_CARRIER,
  carrier,
});

export const setSelectedAmount = (amount) => ({
  type: SET_SELECTED_AMOUNT,
  amount,
});

export const setIntlNumber = (recipientPhoneNumber) => ({
  type: SET_INTERNATIONAL_NUMBER,
  recipientPhoneNumber,
});

export const setAdditionalInfo = (additionalInfo) => ({
  type: SET_ADDITIONAL_INFO,
  additionalInfo,
});

export const customerBalanceFetched = (
  balance,
  mobilephone,
  sinpinTransactionCount
) => ({
  type: CUSTOMER_BALANCE_FETCHED,
  mobilephone,
  balance,
  sinpinTransactionCount,
});

export const customerTopUpHistoryFetched = (data) => ({
  type: CUSTOMER_TOPUP_HISTORY_FETCHED,
  data,
});

export const setFilteredTopUpCarriers = (data) => ({
  type: SET_FILTERED_TOPUP_CARRIERS,
  data,
});

export const clearFilteredTopUpCarriers = () => ({
  type: CLEAR_FILTERED_TOPUP_CARRIERS,
});

export const setFilteredTopUpAmounts = (data) => ({
  type: SET_FILTERED_TOPUP_AMOUNTS,
  data,
});

export const clearFilteredTopUpAmounts = () => ({
  type: CLEAR_FILTERED_TOPUP_AMOUNTS,
});

export const clearTransactionStateAllButUser = () => ({
  type: CLEAR_TRANSACTION_STATE_ALL_BUT_USER,
});

export const clearTransactionState = () => ({
  type: CLEAR_TRANSACTION_STATE,
});

export const setHistoryLoading = (data) => ({
  type: HISTORY_LOADING,
  data,
});
